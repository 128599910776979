export const SignUpDropdowns = {
    affiliations: [
        { 'code': 'AGTA', 'name': 'American Gem Trade Association' },
        { 'code': 'CBG', 'name': 'Continental Buying Group' },
        { 'code': 'CJA', 'name': 'Canadian Jewellers Association' },
        { 'code': 'IJO', 'name': 'Independent Jewelers Organization' },
        { 'code': 'JA', 'name': 'Jewelers of America' },
        { 'code': 'JBT', 'name': 'Jewelers Board of Trade' },
        { 'code': 'JIMA', 'name': 'Jewelry Importers & Manufacturers Association' },
        { 'code': 'MJSA', 'name': 'Manufacturing Jewelers & Suppliers of America' },
        { 'code': 'NPA', 'name': 'National Pawnbrokers Association' },
        { 'code': 'RJO', 'name': 'Retail Jewelers Organization' },
        { 'code': 'None', 'name': 'None' }
    ],
    

    knowledgeSource: ['Online Search', 'Tradeshows', 'MJSA',
                      'Customer Referral', 'UPS Referral', 
                      'Ad', 'Social Media', 'Vendor', 'Previous/Current Accountholder', 
                      'Sales - Name'].sort(),

    noOfEmployees: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31+'],

    noOfLocations: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11+'],

    businessTypes: ['Corporation', 'LLC', 'Sole Proprietorship', 'Joint Venture', 'Partnership'],

    primaryBusiness: ['Manufacturer', 'Retailer', 'Wholesaler', 'Other'],

    products: ['Jewelry', 'Gemstones', 'Watches', 'Luxury Fashion Accessories', 
               'Sports Memorabilia', 'Cell Phones', 'Cameras, Drones', 'Gaming Equipment', 
               'Consumer Electronics (Laptops, Tablets)', 'Other'],

    carriers : [
        'UPS', 
        'USPS', 
        'Brinks', 
        'DHL', 
        'FedEx', 
        'MALCA', 
        'Parcel Pro Secure', 
        'Parcel Pro Select', 
        'Airlines', 
        'APC', 
        'BRT', 
        'Canada Post', 
        'DPD', 
        'EMS', 
        'Ferrari Express', 
        'GCARGO', 
        'GDEX', 
        'GLS', 
        'La Poste', 
        'Mondial Relay', 
        'Poste Italiane', 
        'Purolator', 
        'Royale', 
        'Seafreight', 
        'SF Express', 
        'T-CAT', 
        'TEI', 
        'TNT', 
        'UKRM', 
        'VCARGO', 
        'Yunda', 
        'ZTO'
    ],

    averageShipmentValues: ['Under $1,000', '$1,001 - $5,000', '$5,001 - $25,000', '$25,001 - $50,000', 'Over $50,001'],

    averageShipments: ['1-10', '11-25', '26-50', '51-100'],

    ShippingFrequencyPackages: ['Ship Daily (Everyday)','Ship Weekly (1-4 x a week)','Ship Monthly but not weekly','Seasonal/Occasional Shipper'],

    maximumValues: ['Under $1,000', '$1,001 - $5,000', '$5,001 - $25,000', '$25,001 - $50,000', '$50,001 - $100,000', '$100,001 - $150,000', '$150,001+'],
    
    averageValuePerPackageDropdown: ['$1-$5,000', '$5,000-$10,000', '$10,000-$25,000', '$25,000-$50,000', '$50,000+'],
    maximumValuePerPackageDropdown: ['$1-$5,000', '$5,000-$10,000', '$10,000-$25,000', '$25,000-$50,000', '$50,000+'],

    
    states: [
        { 'code': 'AA', 'country': 'US' },
        { 'code': 'AE', 'country': 'US' },
        { 'code': 'AK', 'country': 'US' },
        { 'code': 'AL', 'country': 'US' },
        { 'code': 'AP', 'country': 'US' },
        { 'code': 'AR', 'country': 'US' },
        { 'code': 'AS', 'country': 'US' },
        { 'code': 'AZ', 'country': 'US' },
        { 'code': 'CA', 'country': 'US' },
        { 'code': 'CO', 'country': 'US' },
        { 'code': 'CT', 'country': 'US' },
        { 'code': 'DC', 'country': 'US' },
        { 'code': 'DE', 'country': 'US' },
        { 'code': 'FL', 'country': 'US' },
        { 'code': 'FM', 'country': 'US' },
        { 'code': 'GA', 'country': 'US' },
        { 'code': 'GU', 'country': 'US' },
        { 'code': 'HI', 'country': 'US' },
        { 'code': 'IA', 'country': 'US' },
        { 'code': 'ID', 'country': 'US' },
        { 'code': 'IL', 'country': 'US' },
        { 'code': 'IN', 'country': 'US' },
        { 'code': 'KS', 'country': 'US' },
        { 'code': 'KY', 'country': 'US' },
        { 'code': 'LA', 'country': 'US' },
        { 'code': 'MA', 'country': 'US' },
        { 'code': 'MD', 'country': 'US' },
        { 'code': 'ME', 'country': 'US' },
        { 'code': 'MH', 'country': 'US' },
        { 'code': 'MI', 'country': 'US' },
        { 'code': 'MN', 'country': 'US' },
        { 'code': 'MO', 'country': 'US' },
        { 'code': 'MP', 'country': 'US' },
        { 'code': 'MS', 'country': 'US' },
        { 'code': 'MT', 'country': 'US' },
        { 'code': 'NC', 'country': 'US' },
        { 'code': 'ND', 'country': 'US' },
        { 'code': 'NE', 'country': 'US' },
        { 'code': 'NH', 'country': 'US' },
        { 'code': 'NJ', 'country': 'US' },
        { 'code': 'NM', 'country': 'US' },
        { 'code': 'NV', 'country': 'US' },
        { 'code': 'NY', 'country': 'US' },
        { 'code': 'OH', 'country': 'US' },
        { 'code': 'OK', 'country': 'US' },
        { 'code': 'OR', 'country': 'US' },
        { 'code': 'PA', 'country': 'US' },
        { 'code': 'PR', 'country': 'US' },
        { 'code': 'PW', 'country': 'US' },
        { 'code': 'RI', 'country': 'US' },
        { 'code': 'SC', 'country': 'US' },
        { 'code': 'SD', 'country': 'US' },
        { 'code': 'TN', 'country': 'US' },
        { 'code': 'TX', 'country': 'US' },
        { 'code': 'UT', 'country': 'US' },
        { 'code': 'VA', 'country': 'US' },
        { 'code': 'VI', 'country': 'US' },
        { 'code': 'VT', 'country': 'US' },
        { 'code': 'WA', 'country': 'US' },
        { 'code': 'WI', 'country': 'US' },
        { 'code': 'WV', 'country': 'US' },
        { 'code': 'WY', 'country': 'US' },
        { 'code': 'AB', 'country': 'CA' },
        { 'code': 'BC', 'country': 'CA' },
        { 'code': 'MB', 'country': 'CA' },
        { 'code': 'NB', 'country': 'CA' },
        { 'code': 'NL', 'country': 'CA' },
        { 'code': 'NS', 'country': 'CA' },
        { 'code': 'NT', 'country': 'CA' },
        { 'code': 'NU', 'country': 'CA' },
        { 'code': 'ON', 'country': 'CA' },
        { 'code': 'PE', 'country': 'CA' },
        { 'code': 'QC', 'country': 'CA' },
        { 'code': 'SK', 'country': 'CA' },
        { 'code': 'YT', 'country': 'CA' }
    ],

    countries: [
        { 'code': 'AF', 'name': 'Afghanistan' },
        { 'code': 'AL', 'name': 'Albania' },
        { 'code': 'DZ', 'name': 'Algeria' },
        { 'code': 'AS', 'name': 'American Samoa' },
        { 'code': 'AD', 'name': 'Andorra' },
        { 'code': 'AO', 'name': 'Angola' },
        { 'code': 'AI', 'name': 'Anguilla' },
        { 'code': 'AG', 'name': 'Antigua and Barbuda' },
        { 'code': 'AR', 'name': 'Argentina' },
        { 'code': 'AM', 'name': 'Armenia' },
        { 'code': 'AW', 'name': 'Aruba' },
        { 'code': 'AU', 'name': 'Australia' },
        { 'code': 'AT', 'name': 'Austria' },
        { 'code': 'AZ', 'name': 'Azerbaijan' },
        { 'code': 'BS', 'name': 'Bahamas' },
        { 'code': 'BH', 'name': 'Bahrain' },
        { 'code': 'BD', 'name': 'Bangladesh' },
        { 'code': 'BB', 'name': 'Barbados' },
        { 'code': 'BE', 'name': 'Belgium' },
        { 'code': 'BZ', 'name': 'Belize' },
        { 'code': 'BJ', 'name': 'Benin' },
        { 'code': 'BM', 'name': 'Bermuda' },
        { 'code': 'BT', 'name': 'Bhutan' },
        { 'code': 'BO', 'name': 'Bolivia' },
        { 'code': 'BQ', 'name': 'Bonaire' },
        { 'code': 'BA', 'name': 'Bosnia-Hercegovina' },
        { 'code': 'BW', 'name': 'Botswana' },
        { 'code': 'BR', 'name': 'Brazil' },
        { 'code': 'IO', 'name': 'British Indian Ocean Terr' },
        { 'code': 'VG', 'name': 'British Virgin Islands' },
        { 'code': 'BN', 'name': 'Brunei' },
        { 'code': 'BG', 'name': 'Bulgaria' },
        { 'code': 'BF', 'name': 'Burkina' },
        { 'code': 'CM', 'name': 'Cameroon' },
        { 'code': 'CA', 'name': 'Canada' },
        { 'code': 'CV', 'name': 'Cape Verde' },
        { 'code': 'KY', 'name': 'Cayman Islands' },
        { 'code': 'TD', 'name': 'Chad' },
        { 'code': 'CL', 'name': 'Chile' },
        { 'code': 'CN', 'name': 'China' },
        { 'code': 'CX', 'name': 'Christmas Island' },
        { 'code': 'CC', 'name': 'Cocos (Keeling) Island' },
        { 'code': 'CO', 'name': 'Colombia' },
        { 'code': 'KM', 'name': 'Comoros' },
        { 'code': 'CG', 'name': 'Congo (Brazzaville)' },
        { 'code': 'CD', 'name': 'Congo (Kinshasa)' },
        { 'code': 'CK', 'name': 'Cook Islands' },
        { 'code': 'CR', 'name': 'Costa Rica' },
        { 'code': 'HR', 'name': 'Croatia' },
        { 'code': 'CW', 'name': 'Curacao' },
        { 'code': 'CY', 'name': 'Cyprus' },
        { 'code': 'CZ', 'name': 'Czech Republic' },
        { 'code': 'DK', 'name': 'Denmark' },
        { 'code': 'DJ', 'name': 'Djibouti' },
        { 'code': 'DM', 'name': 'Dominica' },
        { 'code': 'DO', 'name': 'Dominican Republic' },
        { 'code': 'TL', 'name': 'Egypt' },
        { 'code': 'SV', 'name': 'El Salvador' },
        { 'code': 'GQ', 'name': 'Equatorial Guinea' },
        { 'code': 'ER', 'name': 'Eritrea' },
        { 'code': 'EE', 'name': 'Estonia' },
        { 'code': 'ET', 'name': 'Ethiopia' },
        { 'code': 'FK', 'name': 'Falkland Islands' },
        { 'code': 'FO', 'name': 'Faroe Islands' },
        { 'code': 'FM', 'name': 'Federated States of Micronesia' },
        { 'code': 'FJ', 'name': 'Fiji' },
        { 'code': 'FI', 'name': 'Finland' },
        { 'code': 'FR', 'name': 'France' },
        { 'code': 'GF', 'name': 'French Guiana' },
        { 'code': 'PF', 'name': 'French Polynesia' },
        { 'code': 'TF', 'name': 'French Southern and Antarctic Lands' },
        { 'code': 'GA', 'name': 'Gabon' },
        { 'code': 'GM', 'name': 'Gambia' },
        { 'code': 'GZ', 'name': 'Gaza Strip Administered by Israel' },
        { 'code': 'GE', 'name': 'Georgia' },
        { 'code': 'DE', 'name': 'Germany ' },
        { 'code': 'GH', 'name': 'Ghana' },
        { 'code': 'GI', 'name': 'Gibraltar' },
        { 'code': 'GR', 'name': 'Greece' },
        { 'code': 'GL', 'name': 'Greenland' },
        { 'code': 'GD', 'name': 'Grenada' },
        { 'code': 'GP', 'name': 'Guadeloupe' },
        { 'code': 'GU', 'name': 'Guam' },
        { 'code': 'GT', 'name': 'Guatemala' },
        { 'code': 'GN', 'name': 'Guinea' },
        { 'code': 'GW', 'name': 'Guinea-Bissau' },
        { 'code': 'GY', 'name': 'Guyana' },
        { 'code': 'HT', 'name': 'Haiti' },
        { 'code': 'HM', 'name': 'Heard and McDonald Islands' },
        { 'code': 'HN', 'name': 'Honduras' },
        { 'code': 'HK', 'name': 'Hong Kong' },
        { 'code': 'HU', 'name': 'Hungary' },
        { 'code': 'IS', 'name': 'Iceland' },
        { 'code': 'IN', 'name': 'India' },
        { 'code': 'ID', 'name': 'Indonesia' },
        { 'code': 'IE', 'name': 'Ireland' },
        { 'code': 'IL', 'name': 'Israel' },
        { 'code': 'IT', 'name': 'Italy' },
        { 'code': 'JM', 'name': 'Jamaica' },
        { 'code': 'JP', 'name': 'Japan' },
        { 'code': 'JO', 'name': 'Jordan' },
        { 'code': 'KZ', 'name': 'Kazakhstan' },
        { 'code': 'KE', 'name': 'Kenya' },
        { 'code': 'KI', 'name': 'Kiribati' },
        { 'code': 'KW', 'name': 'Kuwait' },
        { 'code': 'KG', 'name': 'Kyrgyzstan' },
        { 'code': 'LA', 'name': 'Laos' },
        { 'code': 'LV', 'name': 'Latvia' },
        { 'code': 'LS', 'name': 'Lesotho' },
        { 'code': 'LI', 'name': 'Liechtenstein' },
        { 'code': 'LT', 'name': 'Lithuania' },
        { 'code': 'LU', 'name': 'Luxembourg' },
        { 'code': 'MO', 'name': 'Macau' },
        { 'code': 'MK', 'name': 'Macedonia (Skopje)' },
        { 'code': 'MG', 'name': 'Madagascar' },
        { 'code': 'MW', 'name': 'Malawi' },
        { 'code': 'MY', 'name': 'Malaysia' },
        { 'code': 'MV', 'name': 'Maldives' },
        { 'code': 'ML', 'name': 'Mali' },
        { 'code': 'MT', 'name': 'Malta' },
        { 'code': 'MH', 'name': 'Marshall Islands' },
        { 'code': 'MQ', 'name': 'Martinique' },
        { 'code': 'MR', 'name': 'Mauritania' },
        { 'code': 'MU', 'name': 'Mauritius' },
        { 'code': 'YT', 'name': 'Mayotte' },
        { 'code': 'MX', 'name': 'Mexico' },
        { 'code': 'MD', 'name': 'Moldova' },
        { 'code': 'MC', 'name': 'Monaco' },
        { 'code': 'MN', 'name': 'Mongolia' },
        { 'code': 'ME', 'name': 'Montenegro' },
        { 'code': 'MS', 'name': 'Montserrat' },
        { 'code': 'MA', 'name': 'Morocco' },
        { 'code': 'MZ', 'name': 'Mozambique' },
        { 'code': 'NA', 'name': 'Namibia' },
        { 'code': 'NR', 'name': 'Nauru' },
        { 'code': 'NP', 'name': 'Nepal' },
        { 'code': 'NL', 'name': 'Netherlands' },
        { 'code': 'AN', 'name': 'Netherlands Antilles' },
        { 'code': 'NC', 'name': 'New Caledonia' },
        { 'code': 'NZ', 'name': 'New Zealand' },
        { 'code': 'NE', 'name': 'Niger' },
        { 'code': 'NG', 'name': 'Nigeria' },
        { 'code': 'NU', 'name': 'Niue' },
        { 'code': 'NF', 'name': 'Norfolk Island' },
        { 'code': 'MP', 'name': 'Northern Mariana Islands' },
        { 'code': 'NO', 'name': 'Norway' },
        { 'code': 'OM', 'name': 'Oman' },
        { 'code': 'PK', 'name': 'Pakistan' },
        { 'code': 'PW', 'name': 'Palau' },
        { 'code': 'PA', 'name': 'Panama' },
        { 'code': 'PG', 'name': 'Papua New Guinea' },
        { 'code': 'PY', 'name': 'Paraguay' },
        { 'code': 'PE', 'name': 'Peru' },
        { 'code': 'PH', 'name': 'Philippines' },
        { 'code': 'PN', 'name': 'Pitcairn Island' },
        { 'code': 'PL', 'name': 'Poland' },
        { 'code': 'PT', 'name': 'Portugal' },
        { 'code': 'PR', 'name': 'Puerto Rico' },
        { 'code': 'QA', 'name': 'Qatar' },
        { 'code': 'YE', 'name': 'Republic of Yemen' },
        { 'code': 'RE', 'name': 'Reunion' },
        { 'code': 'RO', 'name': 'Romania' },
        { 'code': 'RU', 'name': 'Russia' },
        { 'code': 'MP', 'name': 'Saipan ' },
        { 'code': 'SM', 'name': 'San Marino' },
        { 'code': 'ST', 'name': 'Sao Tome and Principe' },
        { 'code': 'SA', 'name': 'Saudi Arabia' },
        { 'code': 'SF', 'name': 'Scotland' },
        { 'code': 'SN', 'name': 'Senegal' },
        { 'code': 'RS', 'name': 'Serbia' },
        { 'code': 'SC', 'name': 'Seychelles' },
        { 'code': 'SL', 'name': 'Sierra Leone' },
        { 'code': 'SG', 'name': 'Singapore' },
        { 'code': 'SX', 'name': 'Sint Maarten' },
        { 'code': 'SK', 'name': 'Slovakia' },
        { 'code': 'SI', 'name': 'Slovenia' },
        { 'code': 'SB', 'name': 'Solomon Islands' },
        { 'code': 'ZA', 'name': 'South Africa' },
        { 'code': 'KR', 'name': 'South Korea' },
        { 'code': 'ES', 'name': 'Spain (Espana)' },
        { 'code': 'LK', 'name': 'Sri Lanka' },
        { 'code': 'SX', 'name': 'St Maarten and St. Martin' },
        { 'code': 'GP', 'name': 'St. Barthelemy' },
        { 'code': 'AN', 'name': 'St. Eustatius' },
        { 'code': 'SH', 'name': 'St. Helena' },
        { 'code': 'KN', 'name': 'St. Kitts and Nevis' },
        { 'code': 'LC', 'name': 'St. Lucia' },
        { 'code': 'PM', 'name': 'St. Pierre and Miquelon' },
        { 'code': 'VC', 'name': 'St. Vincent and the Grenadines' },
        { 'code': 'SR', 'name': 'Suriname' },
        { 'code': 'SJ', 'name': 'Svalbard, Jan Mayen Island' },
        { 'code': 'SZ', 'name': 'Swaziland' },
        { 'code': 'SE', 'name': 'Sweden' },
        { 'code': 'CH', 'name': 'Switzerland' },
        { 'code': 'TW', 'name': 'Taiwan' },
        { 'code': 'TJ', 'name': 'Tajikistan' },
        { 'code': 'TZ', 'name': 'Tanzania' },
        { 'code': 'TH', 'name': 'Thailand' },
        { 'code': 'TG', 'name': 'Togo' },
        { 'code': 'TK', 'name': 'Tokelau' },
        { 'code': 'TO', 'name': 'Tonga' },
        { 'code': 'TT', 'name': 'Trinidad and Tobago' },
        { 'code': 'TN', 'name': 'Tunisia' },
        { 'code': 'TR', 'name': 'Turkey' },
        { 'code': 'TM', 'name': 'Turkmenistan' },
        { 'code': 'TC', 'name': 'Turks and Caicos Islands' },
        { 'code': 'TV', 'name': 'Tuvalu' },
        { 'code': 'VI', 'name': 'U.S. Virgin Islands' },
        { 'code': 'UG', 'name': 'Uganda' },
        { 'code': 'AE', 'name': 'United Arab Emirates' },
        { 'code': 'GB', 'name': 'United Kingdom' },
        { 'code': 'US', 'name': 'United States' },
        { 'code': 'UY', 'name': 'Uruguay' },
        { 'code': 'UM', 'name': 'US minor outlying Islands' },
        { 'code': 'UZ', 'name': 'Uzbekistan' },
        { 'code': 'VU', 'name': 'Vanuatu' },
        { 'code': 'VA', 'name': 'Vatican City' },
        { 'code': 'VN', 'name': 'Vietnam' },
        { 'code': 'WF', 'name': 'Wallis and Futuna' },
        { 'code': 'WE', 'name': 'West Bank Administered by Israel' },
        { 'code': 'EH', 'name': 'Western Sahara' },
        { 'code': 'WS', 'name': 'Western Samoa' },
        { 'code': 'ZM', 'name': 'Zambia' },
    ]



}
